/* General styles */
body {
  margin: 0;
  padding: 0;
  background-color: #4b306d;
  overflow: hidden;
  overflow-y: auto;
  font-family: 'Roboto', sans-serif;
}

/* Layout styles */
.App {
  text-align: center;
  cursor: default;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Navbar styles */
.navbar {
  display: flex;
  background-color: #ececec00;
  color: #2A1B3D;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  height: 50px;
  z-index: 500;
  position: sticky;
  top:0;
  transition: background-color 0.3s ease;
}

.navbar.scrolled {
  background-color: #4b306d;
  
}

.myName {
  font-size: 1.5rem;
  margin: 0.5rem 1rem;
  color: #ffffff;
}

.navbarLinks ul {
  display: flex;
  margin: 0;
  padding: 0;
}

.navbarLinks li {
  list-style: none;
}

.navbarLinks li a,
.navbarLinks li span {
  text-decoration: none;
  color: #ffffff;
  padding: 15px;
  display: block;
}

.navbarLinks li:hover {
  background-color: #E98074;
}

.toggleButton {
  display: none;
  flex-direction: column;
  height: 30px;
  justify-content: space-between;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
}

.toggleButton .bar {
  background-color: #E98074;
  border-radius: 10px;
  height: 3px;
  width: 100%;
}

.toggleButton .bar.active {
  background-color: #d83f8600;
}

@media (max-width: 416px) {
  .toggleButton {
    display: flex;
  }

  .navbarLinks {
    display: none;
    width: 100%;
  }

  .navbarLinks ul {
    flex-direction: column;
    width: 100%;
  }

  .navbarLinks.active {
    display: flex;
  }

  .navbar {
    align-items: flex-start;
    flex-direction: column;
  }

  .navbarLinks li {
    text-align: center;
    background-color: #A4B3B6;
    box-shadow: 0 0 10px rgba(133, 38, 74, 0.5);
  }

  .navbarLinks li a,
  .navbarLinks li span {
    display: block;
    padding: .5rem;
    text-decoration: none;
  }
}

.navbarLinks li span.flash {
  animation: flash 1s infinite;
  
}

@keyframes flash {
  0%, 100% {
    background-color: #E98074;
  }
  50% {
    background-color: #E98074; 
  }
}

.sectionHeading{
color: #E98074;
font-weight: bolder;
font-size: 3rem;

}

/* About Me section styles */
.aboutMeHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.summaryContainer {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}




/* Bouncing text animation */
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}

.bouncingText {
  animation: bounce 2s infinite;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.box {
  border: 2px solid #2a1b3d;
  padding: 10px;
  max-width: 1000px;
}

/* Coding Section */



/* Coding Section */
.codeSection {
  padding: 100px 0;
  text-align: center;
}

.sectionTitleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.sectionTitle {
  font-size: 32px;
  font-weight: bold;
  color: #333;
}

.divider {
  width: 60px;
  height: 3px;
  background-color: #333;
  margin-top: 10px;
}

.portfolioItems {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}


.portfolioItem:hover {
  transform: translateY(-5px);
}

.portfolioItemLink {
  text-decoration: none;
  color: #333;
}

.portfolioItemTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.portfolioItemDescription {
  font-size: 14px;
  color: #777;
}

/* Welcome section styles */
.welcomeContainer {
 margin-bottom: 20rem;
}

.welcomeText {
  position: absolute;
  margin-top: 10rem;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.fullWidthImage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./images/Purplegir.png');
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;

}

.noteNavbar a{
  text-align: right;
}

/* Footer styles */
footer {
  
  color: rgb(255, 255, 255);
  text-align: center;
  padding: 2px 0;
  margin-top: auto;
}

.footer-links a {
  margin: 5px;
}

/* Coding Container styles */



.codingContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  flex-wrap: wrap; 
  border: none;
}

.leftContent {
  width: 50%;
}

.rightContent {
  width: 50%;
}

.sectionTitle {
  font-size: 32px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.aboutHeading {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.aboutText {
  font-size: 16px;
  color: #777;
  padding: 20px;
}

.projectItem {
  flex-basis: calc(33.33% - 20px);
  margin: 10px;
  background-color: #fff;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
  border: none;
  
}


.projectItem:hover {
  transform: translateY(-5px);
  outline: 1px solid transparent;
  
}



.projectItemTitle {
  font-size: 24px;
  font-weight: bold;
  margin: 10px;
}

.projectItemDescription {
  font-size: 16px;
  color: #777;
  margin: 10px;
}

.codePill {
  display: inline-block;
  background-color: #E98074;
  color: #000000;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 20px;
  margin: 10px;
}

.projectItem.flash {
  animation: flash 1s infinite;
}

.projectsContainer h1{
  color: #E98074;
  font-size: 45px;
 
}

/* Responsive Coding Styles */
@media (max-width: 672px) {
  .codingContainer {
    flex-direction: column;
    align-items: center;
  }
  
  .aboutMeContainer {
    order: -1; 
  }
  
  .leftContent, .rightContent {
    width: 100%;
    margin: 20px 0;
  }
  
  .rightContent {
    order: 2; 
  }
  
  .projectsContainer {
    width: 100%;
  }
  
  .projectItem {
    width: 100%;
    margin: 20px 0;
    padding: 5px;
  }
  
  .codePill {
    margin-right: 10px;
  }
}

/* Contact Me */
.contact-content {
  display: flex;
  flex-direction: column; 
  align-items: center;
  
}

.contact-email,
.contact-social {
  width: 100%; 
  max-width: 300px; 
  margin: 10px;
}

.contact-email h4,
.contact-social h3 {
  font-size: 18px;
  color: #777;
  margin-bottom: 10px;
}

.contact-email p {
  font-size: 18px;
  color: #777;
  margin: 0;
}

.contact-social ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex; 
  justify-content: center; 
  align-items: center; 
}

.contact-social a {
  text-decoration: none;
  color: #333;
  font-size: 18px;
  transition: color 0.3s;
  display: flex; 
  align-items: center; 
}

.contact-social img {
  width: 30px; 
  height: 30px;
  margin-right: 10px; 
}

.contact-social a:hover {
  color: #4b306d;
}







